import { useEffect } from "react";
import moment from "moment"
export default function SelectSuccess(props) {
  const today = moment().format("YYYY-MM-DD")
  useEffect(() => { }, [props.success]);
  return (
    <>
      {props.dbSuccess == null ?
        <>
          <select onChange={(e) => props.handleSuccess(e.target.value)}>
            <option>--Please select--</option>
            <option>Yes</option>
            <option>No</option>
          </select>
          {props.success === true ? (
            <>
              <label>When will the candidate start?</label>
              <input
                type="date"
                min={today}
                onChange={(e) => props.handleStart(e.target.value)}
                id="start_date"
              />
              <label>How many hours will their contract be?</label>
              <input
                type="number"
                pattern="[0-9]*"
                id="contract_hours"
                onChange={(e) => props.handleContract(e.target.value)}
              />
            </>
          ) : props.success === false ? (
            <>
              <label>Reason for not being successful:</label>
              <textarea cols={40} rows={5} onChange={(e) => props.handleNoSuccess(e.target.value)} />
            </>
          ) : null}
        </>
        :
        <></>
      }

    </>
  );
}
