import "./styles/footer.scss";
import JediLogo from "./images/Jedi_HR_logo.webp";
import FooterLogo from "./images/jedi_footer.webp";

const Footer = () => {
    return (
        <footer className="footer">
            <img id="jedi_hr_logo" src={FooterLogo} />
            <div className="footer_right"></div>
        </footer>
    );
};

export default Footer;
